import { useContext } from 'react';
import { Navigate } from 'react-router';
import  UserContext  from '../../Contexts/userContext';

const PrivateRoute = ({ children }) => {
	const {loggedInUser} = useContext(UserContext);
	return loggedInUser?.email ? children : <Navigate to={'/auth'} replace />;
};

export default PrivateRoute;

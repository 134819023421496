import {fetchGet, fetchPost, fetchPut, fetchDelete, GLOBAL_URL} from './global';

// get topic
export const getTopicsList = async () => {
	return await fetchGet(`${GLOBAL_URL}lists/getTopicsList`);
};

// get List Types
export const getListTypes = async () => {
	return await fetchGet(`${GLOBAL_URL}lists/getListTypes`);
};

// get List Items By ID
export const getListItemsByType = async (id) => {
	return await fetchGet(`${GLOBAL_URL}lists/getListItemsByType?type=${id}`);
};

// verify User
export const sendVerificationMail = async (data) => {
	return await fetchPost(`${GLOBAL_URL}users/verifyUser`, data);
};

/////////// Orders Data ////////////

// send order
export const sendOrderData = async (data) => {
	// console.log(data);
	return await fetchPost(`${GLOBAL_URL}orders/createOrder`, data);
};

// send Quota Form
export const sendQuotaForm = async (data) => {
	// console.log(data);
	return await fetchPost(`${GLOBAL_URL}orders/sendRequestToOrder`, data);
};

// get Orders
export const getOrders = async (manager) => {
	return await fetchGet(`${GLOBAL_URL}orders/getAllOrders?manager=${manager}`);
};

//new katy:

export const GetEmailRemainingTime = async () => {
	return await fetchGet(`${GLOBAL_URL}users/getRemainingTime`);
};

export const SendCodeByEmail = async (user_data) => {
	return await fetchPost(`${GLOBAL_URL}users/verifyEmail`, user_data);
};

export const userBeforeSignIn = async (user_data) => {
	return await fetchPost(`${GLOBAL_URL}users/beforeSignIn`, user_data);
}

export const userSignIn = async (user_data) => {
	return await fetchPost(`${GLOBAL_URL}users/signIn`, user_data);
};

export const fetchVerifiedUser = async () => {
	return await fetchGet(`${GLOBAL_URL}users/userVerify`);
};

export const userLogout = async () => {
	return await fetchGet(`${GLOBAL_URL}users/userLogout`);
};

export const checkOrderAbility = async (userEmail) => {
	return await fetchGet(`${GLOBAL_URL}orders/checkOrderAbility?email=${userEmail}`);
};

export const getAllManagers = async () => {
	return await fetchGet(`${GLOBAL_URL}users/getUsers`);
};

export const getAllInstitutions = async () => {
	return await fetchGet(`${GLOBAL_URL}institutions/getInstitutions`);
};

export const getBlockedInstitutions = async () => {
	return await fetchGet(`${GLOBAL_URL}institutions/getBlockedInstitutions`);
};

export const createBlockedInstitution = async (data) => {
	return await fetchPost(`${GLOBAL_URL}institutions/addBlockedInstitution`, data);
};

export const updateInstitutionRequestsCount = async (data) => {
	return await fetchPut(`${GLOBAL_URL}institutions/updateInstitutionMaxRequest`, data);
};

export const updateBlockedInstitution = async (data) => {
	return await fetchPut(`${GLOBAL_URL}institutions/updateBlockedInstitution`, data);
};

export const deleteBlockedInstitution = async (data) => {
	return await fetchDelete(`${GLOBAL_URL}institutions/removeBlockedInstitution`, data);
};
//end katy

export const CreatManagers = async (data) => {
	return await fetchPost(`${GLOBAL_URL}users/createUser`, data);
};

//Update Order Status
export const updateOrderStatus = async (data) => {
	return await fetchPut(`${GLOBAL_URL}orders/updateOrderStatus`, data);
};

// ASSIGN ORDER TO MANAGER:
export const assignOrderToManahger = async (data) => {
	return await fetchPut(`${GLOBAL_URL}orders/assignOrder`, data);
};

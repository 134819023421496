import axios from 'axios';
import { REACT_APP_ENV } from './index';

export const GLOBAL_PUBLIC_URL = REACT_APP_ENV === 'development' ? 'http://localhost:3011/' : 'https://app.feasibilitystudysoroka.org/';
export const GLOBAL_URL = REACT_APP_ENV === 'development' ? 'http://localhost:3011/api/' : 'https://app.feasibilitystudysoroka.org/api/';

export const fetchGet = async (url) => {
	const {data} = await axios.get(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Access-Control-Allow-Origin': GLOBAL_PUBLIC_URL,
		},
	});
	return data;
};

export const fetchPost = async (url, obj, content_type = 'application/json') => {
	const {data} = await axios.post(url, obj, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			'Access-Control-Allow-Origin': GLOBAL_PUBLIC_URL,
		},
	});
	return data;
};

export const fetchPut = async (url, obj, content_type = 'application/json') => {
	const {data} = await axios.put(url, obj, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			'Access-Control-Allow-Origin': GLOBAL_PUBLIC_URL,
		},
	});
	return data;
};

export const fetchDelete = async (url, obj, content_type = 'application/json') => {
	const {data} = await axios.delete(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			'Access-Control-Allow-Origin': GLOBAL_PUBLIC_URL,
		},
		data: obj,
	});
	return data;
};

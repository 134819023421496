import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import './App.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import UserContext from './Contexts/userContext';
import { fetchVerifiedUser } from './utils/api';
import { LinearProgress } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {REACT_APP_ENV, REACT_APP_SITE_KEY_LOCAL, REACT_APP_SITE_KEY_PRODUCTION} from './utils'


const Login = lazy(() => import('./Pages/Login/Login'));
const Dashboard = lazy(() => import('./Pages/Dashboard/Dashboard'));
const MainForm = lazy(() => import('./Pages/MainForm/MainForm'));
const ErrorPage = lazy(() => import('./Pages/ErrorPage/ErrorPage'));

function App() {

	const [loggedInUser, setLoggedInUser] = useState(null);
	const [token, setToken] = useState(null);

	const renderScreen = () => {
		return loggedInUser?.email ? <Navigate to={'/dashboard'} replace /> :
			<Suspense fallback={<LinearProgress />}> 
				<Login /> 
			</Suspense>
	}

	const router = createBrowserRouter([
		{
			path: '/auth',
			element: renderScreen(),
			errorElement: <ErrorPage/>,
		},
		{
			path: '/',
			element:
				<Suspense fallback={<LinearProgress />}>
					<MainForm />
				</Suspense>
			,
			errorElement: <ErrorPage/>,
		},
		{
			path: '/dashboard',
			element: (
				<PrivateRoute>
					<Suspense fallback={<LinearProgress />}>
						<Dashboard />
					</Suspense>
				</PrivateRoute>
			),
			errorElement: <ErrorPage/>,
		},
	]);

	const verifyUser = async () => {
		try {
			const verifiedUser = await fetchVerifiedUser();
			if (verifiedUser.success && verifiedUser.data.email) { //check if has email key(if has email so has rest)
				setLoggedInUser({ ...verifiedUser.data });
				return;
			}
		} catch (error) {
			console.log('error:', error);
		}
	}

	useEffect(() => {
		// throw new Error("Simulated 500 Internal Server Error");
		verifyUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const user_context_data = {
		loggedInUser,
		setLoggedInUser
	}

	return (
		<GoogleReCaptchaProvider reCaptchaKey={REACT_APP_ENV === 'development' ? REACT_APP_SITE_KEY_LOCAL : REACT_APP_SITE_KEY_PRODUCTION}>
			<UserContext.Provider value={user_context_data}>
				{/* <HeaderNav /> */}
				<RouterProvider router={router} />
				{/* <Footer /> */}
				<ToastContainer />
			</UserContext.Provider>
		</GoogleReCaptchaProvider>
	);
}

export default App;
